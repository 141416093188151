import { css } from '@emotion/react';
import { FC } from 'react';
import { base } from 'theme/colors';
import { useTheme } from 'utils';

import { Icon, IconName, IconSize } from '../Icon';

export interface OverflowProps {
  isFilled?: boolean;
  onClose?: () => void;
}

export const Overflow: FC<OverflowProps> = props => {
  const { isFilled, children, onClose } = props;

  const { palette } = useTheme();

  const hasClose = onClose instanceof Function;
  const handleClose = () => {
    if (!(onClose instanceof Function)) return;
    onClose();
  };

  return (
    <div css={root}>
      {children}
      {hasClose && (
        <Icon
          css={[closeIconStyles, { fill: base.white }]}
          name={IconName.Cross}
          size={IconSize.Large}
          onClick={handleClose}
        />
      )}
      {/* eslint-disable-next-line */}
      <div css={[overflowStyles, { background: palette.secondary, opacity: isFilled ? 1 : 0.7 }]} />
    </div>
  );
};

const root = css`
  /* consume z-index from theme variables */
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
`;
const overflowStyles = css`
  z-index: -1;
  position: absolute;

  width: 100%;
  height: 100%;
`;
const closeIconStyles = css`
  cursor: pointer;

  position: absolute;

  top: 2em;
  right: 2em;
`;
