import { ReactComponent as ArrowLeft } from './icons/icons-arrow-left.svg';
import { ReactComponent as ArrowRight } from './icons/icons-arrow-right.svg';
import { ReactComponent as ChevronDown } from './icons/icons-chevron-down.svg';
import { ReactComponent as Cross } from './icons/icons-cross.svg';
import { ReactComponent as Info } from './icons/icons-info.svg';

export enum IconName {
  ArrowLeft = 'arrowLeft',
  ArrowRight = 'arrowRight',
  Cross = 'cross',
  ChevronDown = 'chevronDown',
  Info = 'info'
}

export const iconTypes = {
  [IconName.ArrowLeft]: <ArrowLeft />,
  [IconName.ArrowRight]: <ArrowRight />,
  [IconName.Cross]: <Cross />,
  [IconName.ChevronDown]: <ChevronDown />,
  [IconName.Info]: <Info />
};
