import { css } from '@emotion/react';
import { ReactComponent as Title } from 'assets/images/logo.svg';
import fluffyBall from 'assets/images/small-fluffy-ball.png';
import { FC } from 'react';
import { Image } from 'components/Image';
import { useTheme } from 'utils';

export interface LogoProps {
  hasFluffyBall?: boolean;
  isWhite?: boolean;
}

export const Logo: FC<LogoProps> = props => {
  const { hasFluffyBall, isWhite, ...other } = props;

  const { palette } = useTheme();

  return (
    <div css={logoStyles} {...other}>
      {hasFluffyBall && <Image src={fluffyBall} css={{ width: 73 }} alt="Fluffy Ball" />}
      <Title width="100%" fill={isWhite ? palette.background : undefined} />
    </div>
  );
};

const logoStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 172px;
`;
