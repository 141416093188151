import { FC } from 'react';

export interface ImageProps {
  src: string;
  alt: string;
}

export const Image: FC<ImageProps> = props => {
  const { src, alt, ...other } = props;

  return <img src={src} alt={alt} {...other} />;
};
