import { FC, ReactNode } from 'react';
import { IFontStyles } from 'theme';
import { useTheme } from 'utils';

export interface TextProps {
  text?: ReactNode;
  variant?: keyof IFontStyles;
}

export const Text: FC<TextProps> = props => {
  const { fonts, palette } = useTheme();

  const { variant, children, text, ...other } = props;

  const variantValue = fonts[variant || 'medium'];

  return (
    <div css={[{ color: palette.text.primary }, variantValue]} {...other}>
      {text || children}
    </div>
  );
};
