export const green = {
  50: '#edf7f1',
  100: '#d1ebdb',
  200: '#b3dec3',
  300: '#94d1ab',
  400: '#7dc799',
  500: '#66bd87',
  600: '#5eb77f',
  700: '#53ae74',
  800: '#49a66a',
  900: '#389857',
  A100: '#e5ffed',
  A200: '#b3ffca',
  A400: '#80ffa7',
  A700: '#66ff95'
};
