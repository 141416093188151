import { css } from '@emotion/react';
import { ChangeEventHandler, FC, InputHTMLAttributes, useMemo } from 'react';
import { darkBlueGray } from 'theme';
import { useTheme } from 'utils';

type InputBaseType = InputHTMLAttributes<unknown>;

export interface InputProps {
  value?: InputBaseType['value'];
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  error?: string;
  inputProps?: Omit<InputBaseType, 'onChange'>;
  isMerged?: boolean;
}

export const Input: FC<InputProps> = props => {
  const { value, onChange, inputProps, isMerged, error, ...other } = props;

  const { fonts, palette } = useTheme();

  const dynamicStyles = useMemo(
    () => css`
      color: ${palette.text.default};

      border: 1px solid ${error ? palette.error : darkBlueGray[100]};

      &:focus {
        outline: none;
        border-color: ${palette.text.primary};
        box-shadow: 0 0 0 1px ${palette.text.primary};
      }
    `,
    [palette, error]
  );

  return (
    <input
      css={[fonts.mediumPlus, inputStyles, dynamicStyles, isMerged && mergedInputStyles]}
      value={value}
      onChange={onChange}
      {...inputProps}
      {...other}
    />
  );
};

const inputStyles = css`
  padding: 9px 0 9px 13px;
  height: 42px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 6px;

  border: none;

  line-height: 24px;
`;

const mergedInputStyles = css`
  &:first-of-type {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    &:focus {
      position: relative;
      z-index: 2;
    }
  }
  &:last-of-type {
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &:focus {
      border-top-width: 1px;
    }
  }
`;
