import { useRef } from 'react';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;

export function useId(isActive = true) {
  const id = useRef<number | undefined>();

  if (!isActive) return undefined;

  if (id.current === undefined) id.current = getUniqueId();

  return String(id.current);
}
