import { IFontStyles, IFontWeight, IRawStyle } from 'theme';

import {
  DEFAULT_FONT_FAMILY,
  FONT_FAMILY_FALLBACKS,
  FontSizes,
  FontWeights,
} from './constants';

function getLocalizedFontFamily(language?: string): string {
  // TODO: implement logic for getting valid font according to the language
  return DEFAULT_FONT_FAMILY;
}

function fontFamilyWithFallbacks(fontFamily: string): string {
  return `${fontFamily}, ${FONT_FAMILY_FALLBACKS}`;
}

function createFont(
  fontSize: string,
  fontWeight: IFontWeight,
  fontFamily: string
): IRawStyle {
  return {
    fontFamily,
    fontSize,
    fontWeight,
    MozOsxFontSmoothing: 'grayscale',
    WebkitFontSmoothing: 'antialiased',
  };
}

export function createFontStyles(localeCode?: string): IFontStyles {
  const localizedFont = getLocalizedFontFamily(localeCode);
  const fontFamilyWithFallback = fontFamilyWithFallbacks(localizedFont);

  const fontStyles = {
    tiny: createFont(
      FontSizes.tiny,
      FontWeights.regular,
      fontFamilyWithFallback
    ),
    xSmall: createFont(
      FontSizes.xSmall,
      FontWeights.regular,
      fontFamilyWithFallback
    ),
    small: createFont(
      FontSizes.small,
      FontWeights.regular,
      fontFamilyWithFallback
    ),
    smallPlus: createFont(
      FontSizes.smallPlus,
      FontWeights.regular,
      fontFamilyWithFallback
    ),
    medium: createFont(
      FontSizes.medium,
      FontWeights.regular,
      fontFamilyWithFallback
    ),
    mediumPlus: createFont(
      FontSizes.mediumPlus,
      FontWeights.regular,
      fontFamilyWithFallback
    ),
    large: createFont(
      FontSizes.large,
      FontWeights.regular,
      fontFamilyWithFallback
    ),
    xLarge: createFont(
      FontSizes.xLarge,
      FontWeights.semibold,
      fontFamilyWithFallback
    ),
    xLargePlus: createFont(
      FontSizes.xLargePlus,
      FontWeights.semibold,
      fontFamilyWithFallback
    ),
    xxLarge: createFont(
      FontSizes.xxLarge,
      FontWeights.semibold,
      fontFamilyWithFallback
    ),
    xxLargePlus: createFont(
      FontSizes.xxLargePlus,
      FontWeights.semibold,
      fontFamilyWithFallback
    ),
    superLarge: createFont(
      FontSizes.superLarge,
      FontWeights.bold,
      fontFamilyWithFallback
    ),
    mega: createFont(
      FontSizes.mega,
      FontWeights.semibold,
      fontFamilyWithFallback
    ),
  };

  return fontStyles;
}
