export const darkBlueGray = {
  50: '#eeeef0',
  100: '#d4d6da',
  200: '#b7bac1',
  300: '#9a9ea8',
  400: '#848a96',
  500: '#6e7583',
  600: '#666d7b',
  700: '#5b6270',
  800: '#515866',
  900: '#3f4553',
  A100: '#aec6fe',
  A200: '#7ca3fd',
  A400: '#477eff',
  A700: '#2e6dff'
};
