import { css, jsx } from '@emotion/react';
import { ButtonHTMLAttributes, ElementType, FC, forwardRef } from 'react';

import { ButtonBaseProps } from './types';

export const ButtonBase: FC<ButtonBaseProps> = forwardRef((props, ref) => {
  const { children, component = 'button', type = 'button', disabled = false, tabIndex = 0, onClick, ...other } = props;

  let ComponentProp: ElementType = component;

  if (ComponentProp === 'button' && other.href) {
    ComponentProp = 'a';
  }

  const buttonProps: ButtonHTMLAttributes<unknown> = {};
  if (ComponentProp === 'button') {
    buttonProps.type = type;
    buttonProps.disabled = disabled;
  } else {
    if (ComponentProp !== 'a' || !other.href) {
      buttonProps.role = 'button';
    }
    buttonProps['aria-disabled'] = disabled;
  }

  return jsx(ComponentProp, {
    ref,
    css: rootStyles,
    children,
    onClick,
    disabled,
    tabIndex: disabled ? -1 : tabIndex,
    ...buttonProps,
    ...other,
  });
  // FIXME: do a research for the https://github.com/microsoft/TypeScript/issues/41616
  // return (
  //   <ComponentProp ref={ref} onClick={onClick} tabIndex={disabled ? -1 : tabIndex}>
  //     {children}
  //   </ComponentProp>
  // );
});

const rootStyles = css`
  outline: 0;
  border: 0;
  margin: 0; // Remove the margin in Safari
  cursor: pointer;
  text-decoration: none;
`;
