import { css, keyframes } from '@emotion/react';
import { FC } from 'react';
import { base } from 'theme/colors';
import fluffyBall from 'assets/images/fluffy-ball.png.webp';

import { Image } from '../Image';
import { Overflow } from '../Overflow';
import { Text } from '../Text';

export interface ProgressProps {
  isLoading?: boolean;
  hasOverflow?: boolean;
  message?: string | null;
}

export const Progress: FC<ProgressProps> = props => {
  const { isLoading = true, hasOverflow = false, message = 'Loading', ...other } = props;

  if (!isLoading) return null;

  const loader = (
    <div css={rootStyles} {...other}>
      <div css={getLoaderStyles(hasOverflow)}>
        <Image src={fluffyBall} css={getBallStyles(hasOverflow)} alt="Loader" />
        <div css={getShadowStyles(hasOverflow)} />
      </div>
      {message !== null && (
        <Text
          text={message}
          variant={hasOverflow ? 'large' : 'medium'}
          css={[messageStyles, { color: hasOverflow ? base.white : base.black }]}
        />
      )}
    </div>
  );

  return hasOverflow ? <Overflow isFilled>{loader}</Overflow> : loader;
};

const rootStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// animation keyframes
const bounce = keyframes`
  0% {
    transform: scaleY(.85);
  }
  100% {
    transform: translateY(-70px);
  }  
`;
const scale = keyframes`
  100% {
    transform: scaleX(.5);
    opacity: .01;
  } 
`;
const ellipsis = keyframes`
  100% {
    width: 1em;
  }
`;

const getLoaderStyles = (inOverflow: boolean) => {
  const size = inOverflow ? 90 : 40;

  return css`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: ${size}px;
    height: calc(${size}px + 1em);
  `;
};

const getBallStyles = (inOverflow: boolean) => css`
  position: relative;
  z-index: 100;

  width: 100%;
  opacity: ${inOverflow ? 1 : 0.7};

  animation: ${bounce} 0.5s ease 0.25s infinite alternate;
`;

const getShadowStyles = (inOverflow: boolean) => css`
  position: relative;
  bottom: 12%;

  width: 86%;
  height: 11%;
  border-radius: 50%;

  opacity: ${inOverflow ? 1 : 0.1};
  background-color: #000; // should be always black

  animation: ${scale} 0.5s ease 0.25s infinite alternate;
`;

const messageStyles = css`
  position: relative;
  bottom: 12%;
  margin-right: 0.5em;

  &:after {
    content: '...';

    position: absolute;

    display: inline-block;
    vertical-align: bottom;
    overflow: hidden;

    width: 0px;

    animation: ${ellipsis} steps(4, end) 1s infinite;
  }
`;
