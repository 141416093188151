import { css } from '@emotion/react';
import { FontWeights } from './constants';
import { IFontStyle, IFontWeight, IRawStyle } from '../types';

export interface IFontFaceRule {
  family: string;
  url: string;
  localName?: string;
  weight?: IFontWeight;
  style?: IFontStyle;
}

// FIXME: This is temporary fix in order to sync esm and cjs for this specific case out of the bundler scope.
// We need to find better solution or write own rollup plugin for this.
function _interopDefault(ex: any) {
  return ex && typeof ex === 'object' && 'default' in ex ? ex['default'] : ex;
}
export function getFontFaceRule(rule: IFontFaceRule): IRawStyle {
  const { family, url, weight, style = 'normal', localName } = rule;
  const localSrc = localName ? `local('${localName}'),` : '';

  return css`
    @font-face {
      font-family: '${family}';
      src: ${localSrc +
      `url('${_interopDefault(require(url + '.woff2'))}') format('woff2'),` +
      `url('${_interopDefault(require(url + '.woff'))}') format('woff')`};
      font-weight: ${weight};
      font-style: ${style};
      font-display: swap;
    }
  `;
}

const fontsRules: IFontFaceRule[] = [
  {
    family: 'Gellix',
    url: './assets/Gellix-Thin',
    localName: 'Gellix Thin',
    weight: FontWeights.light,
  },
  {
    family: 'Gellix',
    url: './assets/Gellix-ThinItalic',
    localName: 'Gellix Thin Italic',
    weight: FontWeights.light,
    style: 'italic',
  },
  {
    family: 'Gellix',
    url: './assets/Gellix-Light',
    localName: 'Gellix Light',
    weight: FontWeights.semilight,
  },
  {
    family: 'Gellix',
    url: './assets/Gellix-LightItalic',
    localName: 'Gellix Light Italic',
    weight: FontWeights.semilight,
    style: 'italic',
  },
  {
    family: 'Gellix',
    url: './assets/Gellix-Regular',
    localName: 'Gellix Regular',
    weight: FontWeights.regular,
  },
  {
    family: 'Gellix',
    url: './assets/Gellix-RegularItalic',
    localName: 'Gellix Regular Italic',
    weight: FontWeights.regular,
    style: 'italic',
  },
  {
    family: 'Gellix',
    url: './assets/Gellix-Medium',
    localName: 'Gellix Medium',
    weight: FontWeights.semibold,
  },
  {
    family: 'Gellix',
    url: './assets/Gellix-MediumItalic',
    localName: 'Gellix Medium Italic',
    weight: FontWeights.semibold,
    style: 'italic',
  },
  {
    family: 'Gellix',
    url: './assets/Gellix-Bold',
    localName: 'Gellix Bold',
    weight: FontWeights.bold,
  },
  {
    family: 'Gellix',
    url: './assets/Gellix-BoldItalic',
    localName: 'Gellix Bold Italic',
    weight: FontWeights.bold,
    style: 'italic',
  },
  // TODO: remove if those won't be used after initial phase
  /* {
    family: 'Gellix',
    url: './assets/Gellix-SemiBold',
    localName: 'Gellix SemiBold',
    weight: 600,
  },
  {
    family: 'Gellix',
    url: './assets/Gellix-SemiBoldItalic',
    localName: 'Gellix SemiBold Italic',
    weight: 600,
    style: 'italic',
  },
  {
    family: 'Gellix',
    url: './assets/Gellix-ExtraBold',
    localName: 'Gellix ExtraBold',
    weight: 800,
  },
  {
    family: 'Gellix',
    url: './assets/Gellix-ExtraBoldItalic',
    localName: 'Gellix ExtraBold Italic',
    weight: 800,
    style: 'italic',
  },
  {
    family: 'Gellix',
    url: './assets/Gellix-Black',
    localName: 'Gellix Black',
    weight: 900,
  },
  {
    family: 'Gellix',
    url: './assets/Gellix-BlackItalic',
    localName: 'Gellix Black Italic',
    weight: 900,
    style: 'italic',
  }, */
];

export const fontsStyles = css(fontsRules.map(getFontFaceRule));
