// Standard font sizes
export const size10 = '10px';
export const size12 = '12px';
export const size14 = '14px';
export const size16 = '16px';
export const size18 = '18px';
export const size20 = '20px';
export const size24 = '24px';
export const size28 = '28px';
export const size32 = '32px';
export const size38 = '38px';
export const size68 = '68px';

// Font size aliases
export const tiny = size10;
export const xSmall = size10;
export const small = size12;
export const smallPlus = size12;
export const medium = size14;
export const mediumPlus = size16;
export const icon = size16;
export const large = size18;
export const xLarge = size20;
export const xLargePlus = size24;
export const xxLarge = size28;
export const xxLargePlus = size32;
export const superLarge = size38;
export const mega = size68;
