// FIXME: refactor helpers code

export type ColorDecomposeValues<T = number> = [T, T, T] | [T, T, T, T];
export type ColorFormat = 'rgb' | 'rgba' | 'hsl' | 'hsla';
export interface ColorObject {
  type: ColorFormat;
  values: ColorDecomposeValues;
}

/**
 * Returns a number whose value is limited to the given range.
 *
 * @param {number} value The value to be clamped
 * @param {number} min The lower boundary of the output range
 * @param {number} max The upper boundary of the output range
 * @returns {number} A number in the range [min, max]
 */
function clamp(value: number, min = 0, max = 1) {
  if (process.env.NODE_ENV !== 'production') {
    if (value < min || value > max) {
      // eslint-disable-next-line no-console
      console.error(`Material-UI: The value provided ${value} is out of range [${min}, ${max}].`);
    }
  }

  return Math.min(Math.max(min, value), max);
}

/**
 * Converts a color from CSS hex format to CSS rgb format.
 *
 * @param {string} color - Hex color, i.e. #nnn or #nnnnnn
 * @returns {string} A CSS rgb color string
 */
export function hexToRgb(color: string): string {
  const baseColor = color.substr(1);

  const re = new RegExp(`.{1,${baseColor.length >= 6 ? 2 : 1}}`, 'g');
  let colors = baseColor.match(re);

  if (colors && colors[0].length === 1) {
    colors = colors.map(n => n + n);
  }

  return colors
    ? `rgb${colors.length === 4 ? 'a' : ''}(${colors
        .map((n, index) => {
          return index < 3 ? parseInt(n, 16) : Math.round((parseInt(n, 16) / 255) * 1000) / 1000;
        })
        .join(', ')})`
    : '';
}

/**
 * Returns an object with the type and values of a color.
 *
 * Note: Does not support rgb % values.
 *
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
 * @returns {ColorObject} - A MUI color object: {type: string, values: number[]}
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function decomposeColor(color: string | ColorObject): ColorObject {
  // Idempotent
  if (typeof color !== 'string') return color;

  if (color.charAt(0) === '#') {
    return decomposeColor(hexToRgb(color));
  }

  const marker = color.indexOf('(');
  const type = color.substring(0, marker);

  if (['rgb', 'rgba', 'hsl', 'hsla'].indexOf(type) === -1) {
    throw new Error(
      `Shepper-UI: Unsupported ${color} color.\n` +
        'We support the following formats: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla().'
    );
  }

  const values = color
    .substring(marker + 1, color.length - 1)
    .split(',')
    .map(value => parseFloat(value));

  return { type, values } as ColorObject;
}

/**
 * Converts a color object with type and values to a string.
 *
 * @param {ColorObject} color - Decomposed color
 * @param {string} color.type - One of: 'rgb', 'rgba', 'hsl', 'hsla'
 * @param {array} color.values - [n,n,n] or [n,n,n,n]
 * @returns {string} A CSS color string
 */
export function recomposeColor(color: ColorObject) {
  const { type } = color;
  let { values } = color;

  if (type.indexOf('rgb') !== -1) {
    // Only convert the first 3 values to int (i.e. not alpha)
    values = values.map((n, i) => (i < 3 ? parseInt(String(n), 10) : n)) as ColorDecomposeValues;
  } else if (type.indexOf('hsl') !== -1) {
    (values[1] as unknown as string) = `${values[1]}%`;
    (values[2] as unknown as string) = `${values[2]}%`;
  }

  return `${type}(${values.join(', ')})`;
}

/**
 * Set the absolute transparency of a color.
 * Any existing alpha values are overwritten.
 *
 * @param {string} colorString - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
 * @param {number} value - value to set the alpha channel to in the range 0 -1
 * @returns {string} A CSS color string. Hex input values are returned as rgb
 */
export function fade(colorString: string, value: number): string {
  const color = decomposeColor(colorString);

  if (color.type === 'rgb' || color.type === 'hsl') {
    color.type += 'a';
  }
  color.values[3] = clamp(value);

  return recomposeColor(color);
}

/**
 * Darkens a color.
 *
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
 * @param {number} coefficient - multiplier in the range 0 - 1
 * @returns {string} A CSS color string. Hex input values are returned as rgb
 */
export function darken(colorString: string, coefficient: number) {
  const color = decomposeColor(colorString);
  // eslint-disable-next-line no-param-reassign
  coefficient = clamp(coefficient);

  if (color.type.indexOf('hsl') !== -1) {
    color.values[2] *= 1 - coefficient;
  } else if (color.type.indexOf('rgb') !== -1) {
    for (let i = 0; i < 3; i += 1) {
      color.values[i] *= 1 - coefficient;
    }
  }
  return recomposeColor(color);
}
