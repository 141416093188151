import { ThemeProvider as ThemeProviderBase } from '@emotion/react';
import { FC } from 'react';
import { baseTheme, ITheme, GlobalStyles, GlobalStylesProps } from 'theme';

export interface ThemeProviderProps {
  theme?: ITheme;
  styles?: GlobalStylesProps['styles'];
}

export const ThemeProvider: FC<ThemeProviderProps> = props => {
  const { theme = baseTheme, children, styles } = props;

  return (
    <ThemeProviderBase theme={theme}>
      <GlobalStyles styles={styles} />
      {children}
    </ThemeProviderBase>
  );
};
