import { css, CSSObject } from '@emotion/react';
import { FC, useMemo } from 'react';
import { useTheme } from 'utils';
import { base, darkBlueGray, darken } from 'theme';

import { ButtonBase } from './ButtonBase';
import { ButtonColor } from './ButtonColor';
import { ButtonSize } from './ButtonSize';
import { ButtonProps } from './types';

export const Button: FC<ButtonProps> = (props) => {
  const {
    children,
    component = 'button',
    color = 'default',
    size = 'medium',
    fullWidth,
    startIcon,
    endIcon,
    ...other
  } = props;

  const theme = useTheme();
  const dynamicStyles = useMemo(() => {
    const colorsMap: { [key: string]: CSSObject } = {
      [ButtonColor.default]: {
        color: theme.palette.default,
        backgroundColor: theme.palette.background,
        borderColor: darkBlueGray[100],
      },
      [ButtonColor.primary]: {
        color: base.white,
        backgroundColor: theme.palette.primary,
        borderColor: theme.palette.primary,
      },
      [ButtonColor.secondary]: {
        color: base.white,
        backgroundColor: theme.palette.secondary,
        borderColor: theme.palette.secondary,
      },
    };
    const sizeMap: { [key: string]: CSSObject } = {
      [ButtonSize.small]: {
        ...(theme.fonts.small as CSSObject),
        padding: '3px 9px',
      },
      [ButtonSize.medium]: {
        ...(theme.fonts.medium as CSSObject),
        padding: '9px 18px',
      },
      [ButtonSize.large]: {
        ...(theme.fonts.large as CSSObject),
        padding: '12px 22px',
      },
    };

    return [
      css`
        width: ${fullWidth ? '100%' : 'auto'};

        color: ${colorsMap[color].color};
        border: 1px solid ${colorsMap[color].borderColor};
        background-color: ${colorsMap[color].backgroundColor};

        &:hover {
          background-color: ${darken(
            colorsMap[color].backgroundColor as string,
            theme.palette.action.hoverOpacity
          )};
        }

        &:active {
          background-color: ${darken(
            colorsMap[color].backgroundColor as string,
            theme.palette.action.activatedOpacity
          )};
        }

        &:disabled {
          color: ${theme.palette.action.disabled};
          border: 1px solid ${theme.palette.action.disabledBackground};
          background-color: ${theme.palette.action.disabledBackground};
        }
      `,
      { ...sizeMap[size], fontFamily: 'Arial' },
    ];
  }, [theme, color, size, fullWidth]);

  return (
    <ButtonBase
      css={[rootStyles, ...dynamicStyles]}
      component={component}
      {...other}
    >
      <span css={labelStyles}>
        {startIcon}
        {children}
        {endIcon}
      </span>
    </ButtonBase>
  );
};

const rootStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  border-radius: 6px;

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:disabled {
    cursor: not-allowed;
  }
`;

const labelStyles = css`
  display: inherit;
  align-items: inherit;
  justify-content: inherit;

  width: 100%;
`;
