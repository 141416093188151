import { base, blueGray, darkBlue, darkBlueGray, gray, green, red } from '../colors';
import { IPalette } from '../types';

export function createPalette(options?: Partial<IPalette>): IPalette {
  return {
    default: darkBlueGray[900],
    primary: green[500],
    secondary: darkBlue[500],
    error: red[700],
    background: base.white,
    backgroundContrast: gray[100],
    text: {
      default: gray[700],
      primary: base.black,
      secondary: darkBlueGray[900],
      disabled: 'rgba(0, 0, 0, 0.38)'
    },
    action: {
      activatedOpacity: 0.14,
      // The color of an hovered action.
      hoverOpacity: 0.07,
      // The color of a disabled action.
      disabled: 'rgba(0, 0, 0, 0.26)',
      // The background color of a disabled action.
      disabledBackground: blueGray[100],
      disabledOpacity: 0.38
    },
    ...options
  };
}
