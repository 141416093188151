import { css } from '@emotion/react';
import { FC } from 'react';
import { useTheme } from 'utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CardProps {}

export const Card: FC<CardProps> = props => {
  const { children, ...other } = props;

  const { palette } = useTheme();

  return (
    <div css={[cardStyles, { background: palette.background }]} {...other}>
      {children}
    </div>
  );
};

const cardStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 60px 40px;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
`;
