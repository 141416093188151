import { FC } from 'react';
import { Global, css, Interpolation, Theme } from '@emotion/react';
import { fontsStyles } from './fonts';

const defaultGlobalStyles = css`
  body {
    margin: 0;
    font-family: 'Gellix', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  * {
    box-sizing: border-box;
  }
`;

export interface GlobalStylesProps {
  styles?: Interpolation<Theme>;
}
export const GlobalStyles: FC<GlobalStylesProps> = (props) => {
  const { styles } = props;
  return <Global styles={[defaultGlobalStyles, fontsStyles, styles]} />;
};
