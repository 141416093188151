import { DEFAULT_FONT_STYLES } from './fonts';
import { createPalette } from './palette';
import { ITheme } from './types/ITheme';

/**
 * Creates a custom theme definition
 * @param theme - Partial theme object
 */
export function createTheme(theme: Partial<ITheme> = {}): ITheme {
  const isInverted = !!theme.isInverted;
  const baseTheme: ITheme = {
    isInverted,
    fonts: DEFAULT_FONT_STYLES,
    palette: createPalette()
    // effects: DefaultEffects,
    // spacing: DefaultSpacing
  };

  return { ...baseTheme, ...theme };
}
