import { css, jsx } from '@emotion/react';
import { FC, SyntheticEvent } from 'react';
import { IconName, iconTypes } from './IconType';
import { IconSize } from './IconSize';
import { useTheme } from 'utils';

export interface IconProps {
  name: IconName;
  size?: IconSize;
  onClick?: (event: SyntheticEvent) => void;
}

export const Icon: FC<IconProps> = props => {
  const { onClick, size = IconSize.Medium, name, ...other } = props;

  const { palette } = useTheme();

  return jsx('span', {
    onClick,
    css: [rootStyles, iconSizeStyles(size), { fill: palette.text.primary }],
    children: iconTypes[name],
    ...other,
  });
};

const rootStyles = css`
  display: inline-block;
  outline: 0;
  border: 0;
  margin: 0;
  box-sizing: content-box;
`;

const iconSizeStyles = (size: IconSize) => {
  const sizes = {
    [IconSize.Small]: 8,
    [IconSize.Medium]: 16,
    [IconSize.Large]: 32,
  };

  return css`
    width: ${sizes[size]}px;
    height: ${sizes[size]}px;
  `;
};
