export const darkBlue = {
  50: '#e4e5e7',
  100: '#bcbfc3',
  200: '#8f949b',
  300: '#626973',
  400: '#414955',
  500: '#1f2937',
  600: '#1b2431',
  700: '#171f2a',
  800: '#121923',
  900: '#0a0f16',
  A100: '#5897ff',
  A200: '#2577ff',
  A400: '#005bf1',
  A700: '#0051d8'
};
