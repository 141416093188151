import { useId } from 'hooks';
import { FC, ReactNode } from 'react';
import ReactTooltip, { TooltipProps as BaseTooltipProps } from 'react-tooltip';

export interface TooltipProps extends BaseTooltipProps {
  content?: ReactNode;
}

export const Tooltip: FC<TooltipProps> = props => {
  const { children, content, effect = 'solid', ...other } = props;

  const isHasChildren = !!children;
  const id = useId(isHasChildren);
  other.id = id;
  if (isHasChildren && content) other.getContent = () => content;

  if (children) {
    return (
      <>
        <div data-for={id} data-tip css={{ display: 'flex' }}>
          {children}
        </div>
        <ReactTooltip effect={effect} {...other} />
      </>
    );
  }

  return <ReactTooltip effect={effect} {...other} />;
};
