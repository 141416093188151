import { createTheme } from './utils';

export const baseTheme = createTheme();

export { createTheme };

export * from './colors';
export * from './fonts';
export * from './palette';
export * from './types';
export * from './GlobalStyles';
